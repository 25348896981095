import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

const ContactUsPage = ({ data }) => {
	const { siteUrl } = data.site.siteMetadata.siteUrl;

	//  const html = `<form
	//     action="https://formspree.io/f/"
	//     method="POST">
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Name" type="text" name="name">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Email Address" type="email" name="_replyto">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Telephone Number" type="text" name="telephone">
	//   </div>
	//   <div class="form-group">
	//    <textarea class="form-control bg-light border-0 py-5" rows="3" name="message" placeholder="Message"></textarea>
	//   </div>
	//   // Send Button
	//   <div class="g-recaptcha my-3" data-sitekey=""></div>
	//   <button class="btn btn-outline-primary mt-3" id="contact-send-btn" type="submit">Send Message</button>
	//   </form>`
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				{/* <script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script> */}
			</Helmet>
			<GatsbySeo
				title="Contact Us | Denis Bonnici Electricians Ltd"
				description="Denis Bonnici Electricians Ltd Contact Page"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | Denis Bonnici Electricians Ltd",
					description: "Denis Bonnici Electricians Ltd Contact Page",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<section className="py-md-7 py-4">
				{/*<div dangerouslySetInnerHTML={{ __html: html }} />*/}
				<ContactForm />
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;

export default ContactUsPage;
